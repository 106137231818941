import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import real from './../../services/real'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  width: '30%', 
  marginLeft: '35%',

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

export default function SimpleModal(props) {
  const theme = useTheme();
  
  const { dados, nova_parcela, formas_sem_filtro, open, recusar, updateFieldNovaParcela, valor_troco, handleClose, verificaDataNovaParcela } = props

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <React.Fragment>
          {dados.modal === 'falta_valor' &&
            <StyledPaper theme={theme}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Confirmar Valores</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <p>Os valores informados são inferiores ao valor da parcela.</p>
                  <li>
                    <span>Clique em SIM se deseja complementar o pagamento;</span>
                  </li>
                  <li>
                    <span>Clique em NÃO se deseja lançar uma nova spanarcela com o valor do saldo;</span>
                  </li>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => handleClose()}>Sim</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => recusar()} >Não</Button>
                </Grid>
              </Grid>
            </StyledPaper>
          }
          {dados.modal === 'nova_parcela' &&
            <StyledPaper theme={theme}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Nova Parcela</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <p> Deseja criar uma nova parcela com o valor restante?</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField className="input" label="Valor Restante*" variant="outlined" size="small" value={real(nova_parcela.valor_restante)} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField type='date' className="input" label="Data Vencimento*" variant="outlined" size="small" name='vencimento_nova_parcela' value={nova_parcela.vencimento_nova_parcela} onBlur={e => verificaDataNovaParcela(e)} onChange={e => updateFieldNovaParcela(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: dataHojeFormatado } }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    label="Forma de Pagamento*"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="forma_pgto_nova_parcela"
                    value={nova_parcela.forma_pgto_nova_parcela}
                    onChangeCapture={(e) => updateFieldNovaParcela(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {formas_sem_filtro.map(forma => {
                      return (
                        <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    label="Conta Financeira*"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="conta_fin_nova_parcela"
                    value={nova_parcela.conta_fin_nova_parcela}
                    onChangeCapture={(e) => updateFieldNovaParcela(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={nova_parcela.forma_pgto_nova_parcela === ''}
                  >
                    <option value="">--Escolha--</option>
                    {props.contasFinanceira.filter(param => param.tipo_conta === nova_parcela.habilita).map(contafin => {
                      return (
                        <option key={contafin.id} value={contafin.id}>{contafin.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} className='mg_top_10'>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmarNovaParcela()}>Confirmar</Button>
                </Grid>
                <Grid item md={6} xs={6} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.recusarNovaParcela()} >Cancelar</Button>
                </Grid>
              </Grid>
            </StyledPaper>
          }
          {dados.modal === 'informar_troco' &&
            <StyledPaper theme={theme}>
              <Grid container direction="row" spacing={1} className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h5 className="tituloConfirmacao">Troco</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <p> Por favor retornar ao clinte o troco em dinheiro no valor de:</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <TextField className="input" label="Valor Troco" variant="outlined" size="small" value={real(valor_troco)} InputLabelProps={{ shrink: true }} disabled={true} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <p>ou lançar o troco a partir de uma conta bancaria?</p>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} xs={12} sm={6}>
                  <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.lancarTroco(false)}>Não</Button>
                </Grid>
                <Grid item md={6} xs={12} sm={6}>
                  <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.lancarTroco(true)}>Sim</Button>
                </Grid>
              </Grid>
            </StyledPaper>
          }
        </React.Fragment>
      </Modal>
    </div >
  );
}
