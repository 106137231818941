import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, Button, TextField, FormControlLabel, Switch, FormLabel, FormControl } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CurrencyInput from './../mask/CurrencyInput'
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '90%',
  marginLeft: '5%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  let {dados, contasFinanceira, formasPagamento, itens} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Alterar Vencimentos do Voucher Nº {dados.numero_voucher}</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type='date' className="input" size="small" label="Validade Prom. Atual" variant="outlined" value={dados.data_valid_prom} InputLabelProps={{ shrink: true }} disabled/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                type='date' 
                className="input" 
                size="small" 
                label="Nova Validade Prom." 
                name='nova_data_valid_prom' 
                variant="outlined" 
                value={dados.nova_data_valid_prom} 
                InputLabelProps={{ shrink: true }} 
                onChange={(e) => props.updateField(e)}
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField type='date' className="input" size="small" label="Validade Normal Atual" variant="outlined" value={dados.data_valid_normal} InputLabelProps={{ shrink: true }} disabled/>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                type='date' 
                className="input" 
                size="small" 
                label="Nova Validade Atual" 
                name='nova_data_valid_normal' 
                variant="outlined" 
                value={dados.nova_data_valid_normal} 
                InputLabelProps={{ shrink: true }} 
                onChange={(e) => props.updateField(e)}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
						<Grid item md={4} xs={12} sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Lançar Financeiro?</FormLabel>
								<FormControlLabel className="mg_left_10"
									control={
										<Switch
											name="Ativo"
											color="primary"
											checked={dados.lanc_fin}
										/>
									}
									size="small"
									label={dados.lanc_fin ? 'Sim' : 'Não'}
									name="lanc_fin"
									onChange={(e) => props.updateFieldLancFinanceiro(e)}
								/>
							</FormControl>
						</Grid>
					</Grid>
          {dados.lanc_fin && 
            <React.Fragment>
              <Grid container direction="row" spacing={1} className="mg_top_20">
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    size="small" 
                    label="Tipo da Conta" 
                    variant="outlined" 
                    value={props.conta.tipo} 
                    InputLabelProps={{ shrink: true }} 
                    disabled
                  />
                </Grid>
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Item de Receita"
                    variant="outlined"
                    className="input"
                    fullWidth
                    SelectProps={{
                      native: true,
                    }}
                    name="item_id"
                    size="small"
                    value={props.conta.item_id}
                    onChangeCapture={(e) => props.updateFieldConta(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Selecionar Item de Receita</option>
                    {itens.map(plano => {
                      return (
                        <option value={plano.id}>{plano.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={5} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Descrição*" variant="outlined" name="descricao" value={props.conta.descricao} InputLabelProps={{ shrink: true }} onChange={(e) => props.updateFieldConta(e)}/>
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <TextField className="input" size="small" label="Valor*" variant="outlined" name="valor_total" value={props.conta.valor_total} onChange={(e) => props.updateFieldConta(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
              </Grid>
              {props.conta.valortotal !== '' &&
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <div className='scroll'>
                      <table className="lista sempadding borderTable">
                        <thead>
                          <tr className="titulos acompanha">
                            <th>Parcelas</th>
                            <th>Valor*</th>
                            <th>Vencimento*</th>
                            <th>Forma de Pagamento*</th>
                            <th>Contas Bancárias*</th>
                            <th>Documento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                            return (
                              <tr key={parcela.index}>
                                <td>
                                  <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.num} InputLabelProps={{ shrink: true }} disabled />
                                  <span className="separacaoParcelas">/</span>
                                  <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={props.conta.qtd_parcelas} onChange={(e) => props.updateFieldConta(e)} onBlur={(e) => props.mudarParcelas(e)} InputLabelProps={{ shrink: true }} />
                                </td>
                                <td>
                                  <TextField className="inputValor" variant="outlined" size="small" name="valorparcela" value={parcela.valorparcela} onBlur={(e) => props.recalcularParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                                </td>
                                <td>
                                  <TextField type="date" className="inputVencimento" variant="outlined" size="small" name="datavencimento" value={parcela.datavencimento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                </td>
                                <td>
                                  <TextField
                                    id="standard-select-currency"
                                    select
                                    variant="outlined"
                                    className="inputFormPagamento"
                                    fullWidth
                                    size="small"
                                    SelectProps={{
                                      native: true,
                                    }}
                                    name="formapagto_id"
                                    value={parcela.formapagto_id}
                                    onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                    InputLabelProps={{ shrink: true }}
                                  >
                                    <option value="">--Escolha--</option>
                                    {formasPagamento.map(forma => {
                                      return (
                                        <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                      )
                                    })}
                                  </TextField>
                                </td>
                                <td>
                                  {parseInt(parcela.formapagto_id) === 9 &&
                                    <div 
                                      style={{
                                        cursor: 'pointer',
                                        width: '300px',
                                        border: '1px solid',
                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                        height: '38px',
                                        borderRadius: '5px',
                                        padding: '10px 10px',
                                        marginLeft: '37px'
                                      }} 
                                      onClick={() => props.openVouchers()}
                                    >
                                      <span>{props.vouchersCreditoSelecionadosString}</span>
                                    </div>
                                  }
                                  {parseInt(parcela.formapagto_id) !== 9 &&
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      variant="outlined"
                                      className="inputFormPagamento"
                                      fullWidth
                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      name="contfin_id"
                                      value={parcela.contfin_id}
                                      onChangeCapture={(e) => props.updateFieldParcela(e, parcela.index)}
                                      // onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                      InputLabelProps={{ shrink: true }}
                                    >
                                      <option value="">--Escolha--</option>
                                      {contasFinanceira.filter(param =>  (props.logado.caixa && parseInt(parcela.formapagto_id) === 1 && param.tipo_conta === "CAIXATURNO") || (parseInt(parcela.formapagto_id) !== 1 && param.tipo_conta === parcela.habilita)).map(conta => {
                                        return (
                                          <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                        )
                                      })}
                                    </TextField>
                                  }
                                </td>
                                <td>
                                  <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => props.updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              }
           </React.Fragment>
          }
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} >
            <Grid item md={2} xs={12} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<ArrowBackIcon />} onClick={e => props.confirmar(false)}>Voltar</Button>
            </Grid>
            <Grid item md={8} xs={12} sm={4}></Grid>
            <Grid item md={2} xs={12} sm={4}>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                className="btn_salvar" 
                size="small" 
                startIcon={<CheckIcon />}  
                onClick={e => props.confirmar(true)} 
                disabled={props.motivoCancelar === ''}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}
