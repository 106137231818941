import React from 'react'
import FileList from './../uploadMidia/FileList'
import Upload from './../uploadMidia/Upload'
import { Grid, TextField } from '@mui/material'
import moment from 'moment';
import InputMask from "react-input-mask";

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

let formatos = [
  {
    type: "text",
    data_type: "character varying"
  },
  {
    type: "date",
    data_type: "date"
  },
  {
    type: "number",
    data_type: "integer"
  },
]

const FormPessoaFisica = ({ form, dados, updateField, validarCpfCnpj, disabledCPF, validarCpfCnpjConjugue, 
  dadosComplementaresPF, updateFieldDadosComplementar, metodos_divugacoes, handleUpload }) => (
  <React.Fragment>
    <Grid container direction="row" className="mg_top_10">
      <Grid item md={12}>
        <h5>Dados Pessoais - Pessoa Física:</h5>
      </Grid>
    </Grid>

    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={dados.nome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={dados.sobrenome} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          fullWidth
          className="input"
          type='date'
          name='dataNascimento'
          label="Data Nascimento*"
          value={dados.dataNascimento}
          variant="outlined"
          size="small"
          onChange={(e) => updateField(e)}
          InputProps={{ inputProps: { max: dataHojeFormatado } }}
          InputLabelProps={{
            shrink: true,
          }} />
        {/* <span id={`error-pf-${form}-dataNascimento`} className="error">Informar Campo Data de Nascimento</span> */}
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField 
          inputProps={{ maxLength: 11 }} 
          className="input" 
          label="CPF*" 
          variant="outlined" 
          size="small" 
          name="cpf" 
          value={dados.cpf} 
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
          onBlur={e => validarCpfCnpj(e)} 
          disabled={disabledCPF ? true : (dados.dataNascimento === '' ? true : false)} 
        />
      </Grid>
    </Grid>
    <br />
    <hr />
    {dadosComplementaresPF.list.length > 0 &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <h5 className='titulo'>Dados Complementares:</h5>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
            if(value.column_name === 'estado_civil'){
              return (
                <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="">Selecionar Estado Civil</option>
                    <option value="CASADO">Casado(a)</option>
                    <option value="SOLTEIRO">Solteiro(a)</option>
                    <option value="VIUVO">Viuvo(a)</option>
                    <option value="SEPARADO">Separado(a)</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'sexo'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="">Selecionar Sexo</option>
                    <option value="MASCULINO">Masculino</option>
                    <option value="FEMININO">Feminino</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'rg_uf'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="">Selecionar Estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'rg_orgao_emissor'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="">Selecionar Orgão Emissor</option>
                    <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                    <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                    <option value="PC - Policia Civil">PC - Policia Civil</option>
                    <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                    <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                    <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                    <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                    <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                    <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                    <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                    <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                    <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                    <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                    <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                    <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                    <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                    <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                    <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                    <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                    <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                    <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                    <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                    <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'rg_orgao_emissor_conjugue'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="">Selecionar Orgão Emissor</option>
                    <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                    <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                    <option value="PC - Policia Civil">PC - Policia Civil</option>
                    <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                    <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                    <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                    <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                    <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                    <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                    <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                    <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                    <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                    <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                    <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                    <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                    <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                    <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                    <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                    <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                    <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                    <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                    <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                    <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'receber_novidades'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value="SIM">Sim</option>
                    <option value="NAO">Não</option>
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'como_conheceu_id'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  >
                    <option value={value.id}>Selecionar Como Conheceu?</option>
                   {metodos_divugacoes.map(value => {
                     return (
                       <option key={value.id} value={value.id}>{value.descricao}</option>
                     )
                   })}
                  </TextField>
                </Grid>
              )
            }else if(value.column_name === 'telefone_conjugue'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={dados.dadosComplementar[value.column_name]}
                    maskChar=""
                    onChange={(e) => updateFieldDadosComplementar(e)} 
                  >
                    {() =>
                      <TextField 
                      className="input" 
                      label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                      variant="outlined" 
                      size="small" 
                      name={value.column_name} 
                      InputLabelProps={{ shrink: true }} />
                    }
                  </InputMask>
                </Grid>
              )
            }else if(value.column_name === 'cpf_conjugue'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <TextField 
                    inputProps={{ maxLength: 11 }} 
                    className="input" 
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    variant="outlined" 
                    size="small" 
                    name={value.column_name}
                    value={dados.dadosComplementar[value.column_name]}
                    onChange={(e) => updateFieldDadosComplementar(e)} 
                    InputLabelProps={{ shrink: true }} 
                    onBlur={e => validarCpfCnpjConjugue(e)}
                  />
                </Grid>
              )
            }else if(value.column_name === 'doc_rg_frente'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>RG Frente:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                  <span>{dados.files['doc_rg_frente'][0] ? dados.files['doc_rg_frente'][0].name : ''}</span>
                </Grid>
              )
            }else if(value.column_name === 'doc_rg_verso'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>RG Verso:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_rg_verso')} />
                  <span>{dados.files['doc_rg_verso'][0] ? dados.files['doc_rg_verso'][0].name : ''}</span>
                </Grid>
              )
            }else if(value.column_name === 'doc_cnh_frente'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>CNH Frente:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_frente')} />
                  <span>{dados.files['doc_cnh_frente'][0] ? dados.files['doc_cnh_frente'][0].name : ''}</span>
                </Grid>
              )
            }else if(value.column_name === 'doc_cnh_verso'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>CNH Verso:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_verso')} />
                  <span>{dados.files['doc_cnh_verso'][0] ? dados.files['doc_cnh_verso'][0].name : ''}</span>
                </Grid>
              )
            }else if(value.column_name === 'doc_comprovante_renda'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>Comprovante de Renda:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_renda')} />
                  <span>{dados.files['doc_comprovante_renda'][0] ? dados.files['doc_comprovante_renda'][0].name : ''}</span>
                </Grid>
              )
            }else if(value.column_name === 'doc_comprovante_endereco'){
              return (
                <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                  <span style={{color: "#000", fontWeight: 400}}>Comprovante de Endereço:</span>
                  <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_endereco')} />
                  <span>{dados.files['doc_comprovante_endereco'][0] ? dados.files['doc_comprovante_endereco'][0].name : ''}</span>
                </Grid>
              )
            }else{
              return (
                <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                  <TextField
                    fullWidth
                    className="input"
                    type={formatos.filter(param => param.data_type === value.data_type)[0] ? formatos.filter(param => param.data_type === value.data_type)[0].type : 'text'}
                    name={value.column_name}
                    label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                    value={dados.dadosComplementar[value.column_name]}
                    variant="outlined"
                    size="small"
                    onChange={(e) => updateFieldDadosComplementar(e)}
                    InputLabelProps={{shrink: true}} 
                  />
                </Grid>
              )
            }
          })}
        </Grid>
        <br />
        <hr />
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormPessoaFisica