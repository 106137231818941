import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Countdown from './../templates/Countdown'

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '94%',
  marginLeft: '3%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  height: '90%',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} className={props.boletoSelecionado.tipo_transaction === 'BOLETO' ? '': 'paperPix'}>
          {props.boletoSelecionado.tipo_transaction === 'BOLETO' &&
            <iframe title="Boleto"  style={{width: '100%', height: '100%'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} ></iframe>
          }

          {props.boletoSelecionado.tipo_transaction === 'PIX' &&
            <React.Fragment>
              {props.boletoSelecionado.parceiro_bancario_id === 1 &&
                <React.Fragment>
                  <Tooltip title='Copiar QRCode' aria-label="add">
                    <img onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.boleto_digitable_line)} style={{width: '100%', cursor: 'pointer'}} src={props.boletoSelecionado.bank_slip.url_slip_pdf} alt="qrcode" />
                  </Tooltip>
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} sm={12}>
                      <Tooltip title='Copiar Texto' aria-label="add">
                        <p onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.boleto_digitable_line)} style={{fontSize: '16px', textAlign: 'center', cursor: 'pointer'}}><b>PIX COPIA E COLA</b></p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" style={{marginTop: 10}}>
                    <Grid item md={12} xs={12} sm={12}>
                      <p style={{fontSize: '18px', textAlign: 'center', cursor: 'pointer'}}>Pix expira em <Countdown targetDate={props.boletoSelecionado.expiracao_pix} /></p>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
              {props.boletoSelecionado.parceiro_bancario_id === 2 &&
                <React.Fragment>
                  {/* <Tooltip title='Copiar QRCode' aria-label="add">
                  </Tooltip> */}
                  <img style={{width: '100%', cursor: 'pointer'}} src={`data:image/jpeg;base64,${props.boletoSelecionado.bank_slip.url_slip_pdf}`} alt="qrcode" />
                  <Grid container spacing={1} direction="row">
                    <Grid item md={12} xs={12} sm={12}>
                      <Tooltip title='Copiar Texto' aria-label="add">
                        <p onClick={e => props.copyLink(props.boletoSelecionado.bank_slip.boleto_digitable_line)} style={{fontSize: '16px', textAlign: 'center', cursor: 'pointer'}}><b>PIX COPIA E COLA</b></p>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </React.Fragment>
          }
          <br />
        </StyledPaper>
      </Modal>
    </div>
  );
}
